<template>
  <div class='container'>
    <Treeselect 
      ref="tree"
      :value="selectArr"
      :loadOptions="loadOptions"
      :auto-load-root-options="false"
      :options="list"
      :multiple="true" 
      :alwaysOpen="true"
      :placeholder="'请选择测评人员'"
      :normalizer="normalizer"
      :searchable="false"
      :defaultOptions="true"
      @input="updateValue"
      @select="select"
    >
    </Treeselect>
    <div class="bottom" @click="confirm">
      确认选择          
    </div>
  </div>
</template>

<script>
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import {getDepartments,wxworkExamAdd} from '@/api/project.js'
import { LOAD_ROOT_OPTIONS } from '@riophae/vue-treeselect'

export default {
  components: { Treeselect },
  data(){
    return{
      list:null, 
      items: [
              {text: 'Item 1'},
              {text: 'Item 2'},
              {text: 'Item 3', children: [
                {text: 'Item 3.1'},
                {text: 'Item 3.2'}
              ]},
        {
          text: '园区其他机构',
          children: [
            {
              text: '园区其他机构1',
              children: [
                { text: '园区其他机构1.1.1' },
                { text: '园区其他机构1.1.2' },
                { text: '园区其他机构1.1.3' },
              ],
            },
            {
              text: '园区其他机构2',
              children: [
                { text: '园区其他机构2.1.1' },
                { text: '园区其他机构2.1.2' },
                { text: '园区其他机构2.1.3' },
              ],
            },
          ],
        },
      ],
      selectArr:[],
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
          children: node._child,
        }
      },
      /* options:{ 
        multiple:true,
        checkbox: true,
        fetchData(node) {
          return getDepartments()
          .then(res=>res.data.list)
        },
        propertyNames:{
         'text': 'name',
         'children': '_child'
        }
      } */
    }
  },
  created(){
    // console.log(JSON.parse(this.$route.query.form));
    this.getDepartmentList()
    
  },
  mounted(){
    setTimeout(() => {
      this.selectArr = JSON.parse(this.$route.query.form).leafNode // 将你的值替换为实际的值
    }, 200);

  },
  methods:{
    async getDepartmentList (){
      const res = await getDepartments()
      this.list=res.data.list
      // console.log(this.list);
    },
    // 选项值改变触发事件
   updateValue(value,id){
    //  console.log('value',value);
    //  console.log('id',id);
     this.selectArr=value
    //  console.log('this.selectArr',this.selectArr);
   },
   select(node,id){
    console.log(114,node,id);
   },
   confirm(){
    const form=JSON.parse(this.$route.query.form)
    if (this.selectArr.length===0) {
      this.$toast.fail('请至少选择一个测评部门')
      return
    }
    form.leafNode=this.selectArr
    const selectNames=this.$refs.tree.selectedNodes.map(item=>{
      if(this.selectArr.indexOf(item.id)>-1){
        return item.label
      }
      return null
    }).filter(item=>item!==null)
    console.log(selectNames);
    this.$router.push({path:'add',query:{
      step:2,
      form:JSON.stringify(form),
      selectNames:JSON.stringify(selectNames)
    },
    })
   },
   async loadOptions({ action }) {
     if (action === LOAD_ROOT_OPTIONS) {

      }
   },
  }
}
</script>
<style lang='less' scoped>
.container{
  min-height: 100%;
  .bottom{
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 13.8667vw;
    text-align: center;
    line-height: 13.8667vw;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(70, 70, 70, 1);
    font-size: 4.8vw;
  }
}
</style>